import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Splide from '@splidejs/splide';

const initializeSliders = () => {
  const testimonialSlider = document.querySelector('.m-testimonial-slider');
  if(testimonialSlider) {
    new Splide('.splide.m-testimonial-slider', {
      type: 'loop',
      gap: '2.2rem',
      perPage: 2.5,
      drag: 'free',
      snap: true,
      focus: 0,
      omitEnd: true,
      breakpoints: {
        640: {
          destroy: true,
          fixedWidth: 585,
        },
      }
    }).mount()
  }

  const marqueeSlider = document.querySelector('.marquee-brands');
  if(marqueeSlider) {
    new Splide('.splide.brands', {
      type: 'loop',
      drag: false,
      omitEnd: true,
      perPage: 9,
      gap: '8vw',
      autoScroll: {
        speed: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({AutoScroll})
  }
}

const init = () => {
  initializeSliders();
}

// Allow the function to be exported so it can be consumed in Storybook.
export default init;