/**
 * Class representing the main menu navigation.
 */
 export default class Menu {
  constructor(navbarSelector, generateOverlay = false) {
    //TODO: Add overlay generation

    this.menuToggleBtn = document.getElementById('menu-toggle');
    this.navbar = document.querySelector(navbarSelector);
    this.mainMenuSub = document.querySelector('.header__nav-wrapper');
    
    if (this.menuToggleBtn && this.navbar) {
    this.attachEventListeners();
    }
  }

  attachEventListeners() {
    this.menuToggleBtn.addEventListener('click', this.toggleMenu.bind(this));
  }

  toggleMenu() {
    this.menuToggleBtn.classList.toggle('is-active');
    this.menuToggleBtn.classList.toggle('inactive');
    this.navbar.classList.toggle('menu-open');
    
    //Toggle aria-expanded attribute used for animating the hamburger icon
    if ( this.menuToggleBtn.getAttribute( 'aria-expanded' ) === 'true' ) {
			this.menuToggleBtn.setAttribute( 'aria-expanded', 'false' );
      document.documentElement.classList.remove("no-scroll");
		} else {
			this.menuToggleBtn.setAttribute( 'aria-expanded', 'true' );
      document.documentElement.classList.add("no-scroll");
		}
  }
}
